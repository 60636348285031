import { lazy } from 'react'

import { urlRoutes } from '../../../config/urlRoutes/urlRoutes'

export const RegionRoutes = [
	{
		nameUrl: 'Provincias',
		path: urlRoutes.control.region.url,
		element: lazy(() => import('../../../../pages/control/region')),
		rols: ['*'],
	},
	{
		nameUrl: 'Provincias / Crear',
		path: urlRoutes.control.region.create.urlTo,
		element: lazy(() => import('../../../../pages/control/region/create')),
		rols: ['*'],
	},
	{
		nameUrl: 'Provincias / Editar',
		path: `${urlRoutes.control.region.url}/:id`,
		element: lazy(() => import('../../../../pages/control/region/edit')),
		rols: ['*'],
	},
]
