import React from 'react'
import { useState } from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import { urlRoutes } from '../../routes/config/urlRoutes/urlRoutes';
import {setManagerUser} from "../../redux/slices/managerUserSlice";
import {useDispatch, useSelector} from "react-redux";
import { getManagerUser } from '../../redux/slices/managerUserSlice'

export default function AccountMenu() {

    const dispatch = useDispatch()

    const navigate = useNavigate()

	const userName = useSelector(getManagerUser)

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        // setAnchorEl(event.currentTarget);
        setAnchorEl(open ? null : event.currentTarget);
    };

    const handleClose = (e: any) => {
        setAnchorEl(null);
        // handleClick(e)
    };

    const handleLogout = () => {
        localStorage.clear()
        dispatch(setManagerUser({}))
        navigate(urlRoutes.auth.login.urlTo)
    }

    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <Tooltip title="Configuraciones de la cuenta">
                    <Button
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <Avatar sx={{ width: 32, height: 32 }}>{userName?.managerUser?.username.substring(0, 1)}</Avatar>
                        <div>
                            <Typography color="white" fontSize={12} fontWeight="bold" align="left" ml={1}>
                                {userName?.managerUser?.username}
                            </Typography>
                            <Typography fontSize={10} ml={1} align="left" color="white">
                                {userName?.managerUser?.userType}
                            </Typography>
                        </div>
                    </Button>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={(e) => handleClose(e)}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {/* <MenuItem>
                    <ListItemIcon>
                        <Settings fontSize="small" />
                    </ListItemIcon>
                    Configuración
                </MenuItem> */}
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Cerrar sesión
                </MenuItem>
            </Menu>
        </>
    );
}
