import { lazy } from 'react'

import { urlRoutes } from '../../../config/urlRoutes/urlRoutes'

export const CountryRoutes = [
	{
		nameUrl: 'Paises',
		path: urlRoutes.control.countries.url,
		element: lazy(() => import('../../../../pages/control/country')),
		rols: ['*'],
	},
	{
		nameUrl: 'Paises / Crear',
		path: urlRoutes.control.countries.create.urlTo,
		element: lazy(() => import('../../../../pages/control/country/create')),
		rols: ['*'],
	},
	{
		nameUrl: 'Paises / Editar',
		path: `${urlRoutes.control.countries.url}/:id`,
		element: lazy(() => import('../../../../pages/control/country/edit')),
		rols: ['*'],
	},
]
