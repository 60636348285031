import { lazy } from 'react'

import { urlRoutes } from '../../../config/urlRoutes/urlRoutes'

export const MembersUsersToPaymentRecurrentRoutes = [
	{
		nameUrl: 'Miembros / próximo cobro recurrente',
		path: urlRoutes.control.memberUserToRecurrentPayment.url,
		element: lazy(
			() => import('../../../../pages/control/memberUsersToPaymentRecurrent')
		),
		rols: ['*'],
	},
]
