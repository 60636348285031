import React from 'react'
import { createTheme } from '@mui/material/styles'
import Red from '@mui/material/colors/red'
import { styled } from '@mui/material/styles'
import Radio, { RadioProps } from '@mui/material/Radio'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

const BpIcon = styled('span')(({ theme }) => ({
	borderRadius: '50%',
	width: 16,
	height: 16,
	boxShadow:
		theme.palette.primary.main === 'dark'
			? '0 0 0 1px rgb(16 22 26 / 40%)'
			: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
	backgroundColor:
		theme.palette.primary.main === 'dark' ? '#394b59' : '#f5f8fa',
	backgroundImage:
		theme.palette.primary.main === 'dark'
			? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
			: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
	'.Mui-focusVisible &': {
		outline: '2px auto rgba(19,124,189,.6)',
		outlineOffset: 2,
	},
	'input:hover ~ &': {
		backgroundColor:
			theme.palette.primary.main === 'dark' ? '#30404d' : '#ebf1f5',
	},
	'input:disabled ~ &': {
		boxShadow: 'none',
		background:
			theme.palette.primary.main === 'dark'
				? 'rgba(57,75,89,.5)'
				: 'rgba(206,217,224,.5)',
	},
}))

const BpCheckedIcon = styled(BpIcon)({
	backgroundColor: '#7D112A',
	backgroundImage:
		'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
	'&:before': {
		display: 'block',
		width: 16,
		height: 16,
		backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
		content: '""',
	},
	'input:hover ~ &': {
		backgroundColor: '#106ba3',
	},
})

export const BpRadio = (props: RadioProps) => {
	return (
		<Radio
			disableRipple
			color="default"
			checkedIcon={<BpCheckedIcon />}
			icon={<BpIcon />}
			{...props}
		/>
	)
}

export const theme = createTheme({
	palette: {
		primary: {
			main: '#770027',
		},
		secondary: {
			main: '#624543',
		},
		background: {
			default: '#fff',
		},
	},

	typography: {
		fontFamily: ['"Roboto"'].join(','),
	},

	components: {
		MuiAppBar: {
			styleOverrides: {
				colorPrimary: {
					backgroundColor: 'black',
					boxShadow: 'none',
					borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
				},
			},
		},
		MuiInputLabel: {
			defaultProps: {
				sx: {
					fontSize: '20px',
					color: '#000',
					fontWeight: '500',
					// whiteSpace: 'normal',
					overflow: 'unset',

					'& span': {
						color: Red[500],
					},
				},
			},
		},
		MuiFormLabel: {
			defaultProps: {
				sx: {
					color: '#000',
					fontWeight: '500',
					fontSize: '15px',

					'& span': {
						color: Red[500],
					},
				},
			},
		},
	},
})
