import { createSlice } from '@reduxjs/toolkit'

export const countrySlice = createSlice({
	name: 'country',
	initialState: {
		entities: [],
		pagination: {
			page: 0,
			pageSize: 0,
			total: 0,
		},
	},
	reducers: {
		setCountries: (state, action) => {
			state.entities = action.payload.entities
			state.pagination = action.payload.pagination
		},
	},
})

export const getCountries = (state: any) => state.country

export const { setCountries } = countrySlice.actions

export default countrySlice.reducer
