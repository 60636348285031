import { lazy } from 'react'

import { urlRoutes } from '../../config/urlRoutes/urlRoutes'

export const CouponRoutes = [
	{
		nameUrl: 'Cupones',
		path: urlRoutes.coupon.url,
		element: lazy(() => import('../../../pages/coupon')),
		rols: ['*'],
	},
	{
		nameUrl: 'Cupones / Crear',
		path: urlRoutes.coupon.create.urlTo,
		element: lazy(() => import('../../../pages/coupon/create')),
		rols: ['*'],
	},
	{
		nameUrl: 'Cupones / Editar',
		path: `${urlRoutes.coupon.url}/:id`,
		element: lazy(() => import('../../../pages/coupon/edit')),
		rols: ['*'],
	},
]
