import { createSlice } from '@reduxjs/toolkit'

export const regionSlice = createSlice({
    name: 'region',
    initialState: {
        entities: [],
        pagination: {
            page: 0,
            pageSize: 0,
            total: 0
        },
    },
    reducers: {
        setRegion: (state, action) => {
            state.entities = action.payload.entities
            state.pagination = action.payload.pagination
        },
    },
})

export const getRegions = (state: any) => state.region

export const { setRegion } = regionSlice.actions

export default regionSlice.reducer
