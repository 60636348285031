import { createSlice } from '@reduxjs/toolkit'

export const managersSlice = createSlice({
	name: 'managers',
	initialState: {
        entities: [],
        pagination: {
            page: 0,
            pageSize: 0,
            total: 0
        },
    },
	reducers: {
		setManagers: (state, action) => {
			state.entities = action.payload.entities
            state.pagination = action.payload.pagination
		},
	},
})

export const getManagersName = (state: any) => state.managers

export const { setManagers } = managersSlice.actions

export default managersSlice.reducer
