import React from 'react'
import { lazy } from 'react';
import { Routes, Route } from 'react-router-dom'

import Layout from "../../components/layout";

import { AuthRoutes } from './authRoutes/authRoutes';

const NotFound = lazy(() => import("../../pages/404"));

export const publicRoutes = [
    ...AuthRoutes,
]

export default function PublicPages() {
    return (
        <Layout>
            <Routes>
                <Route path="*" element={<NotFound />} />

                {publicRoutes.map(routes => (
                    <Route
                        key={routes.path}
                        path={routes.path}
                        element={<routes.element />}
                    />
                ))}
            </Routes>
        </Layout>
    )
}