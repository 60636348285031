import IManagerUserRepository from '../../domain/interfaces/repositories/IManagerUserRepository'
import {
	AuthLoginManagerUserRequest,
	AuthLoginManagerUserResponse,
	CreateManagerUserCommandRequest,
	CreateManagerUserCommandResponse,
	ManagerUser,
	ReadManagerUserRecordsQueryResponse,
	ReadManagerUsersQueryResponse,
	RecoverPasswordManagerUserCommandRequest,
	ResetPasswordManagerUserCommandRequest,
	UpdateManagerUserCommandRequest,
	UpdateManagerUserCommandResponse,
} from '../../domain/entities/managerUser'
import { QueryModel } from '../../domain/models/queryModel'
import { sendRequest } from '../dataSources/http/axios'
import { getQueryString } from '../utils/format'
import { AsyncApiResponse } from '../../domain/models/apiResponse'

export default class ManagerUserRepository implements IManagerUserRepository {
	async login(
		data: AuthLoginManagerUserRequest
	): AsyncApiResponse<AuthLoginManagerUserResponse> {
		const request = {
			post: {
				path: `${process.env.REACT_APP_URL_BASE}/users/managers/auth/login`,
				body: data,
			},
		}
		return await sendRequest<AuthLoginManagerUserResponse>(request)
	}

	async getById(id: string): AsyncApiResponse<ManagerUser> {
		const request = {
			get: {
				path: `${process.env.REACT_APP_URL_BASE}/users/manager/${id}`,
			},
		}
		return await sendRequest<ManagerUser>(request)
	}

	async getAll(
		query: QueryModel
	): AsyncApiResponse<ReadManagerUsersQueryResponse> {
		const request = {
			get: {
				path: `${process.env.REACT_APP_URL_BASE}/users/manager${
					query ? `?${getQueryString(query)}` : ''
				}`,
			},
		}
		return await sendRequest<ReadManagerUsersQueryResponse>(request)
	}

	async create(
		data: CreateManagerUserCommandRequest
	): AsyncApiResponse<CreateManagerUserCommandResponse> {
		const request = {
			post: {
				path: `${process.env.REACT_APP_URL_BASE}/users/manager/create`,
				body: data,
			},
		}
		return await sendRequest<CreateManagerUserCommandResponse>(request)
	}

	async update(
		data: UpdateManagerUserCommandRequest
	): AsyncApiResponse<UpdateManagerUserCommandResponse> {
		const request = {
			post: {
				path: `${process.env.REACT_APP_URL_BASE}/users/manager/update`,
				body: data,
			},
		}
		return await sendRequest<UpdateManagerUserCommandResponse>(request)
	}

	async refreshToken(
		refreshToken: string
	): AsyncApiResponse<AuthLoginManagerUserResponse> {
		const request = {
			post: {
				path: `${process.env.REACT_APP_URL_BASE}/refresh-token`,
				body: { refreshToken: refreshToken },
			},
		}
		return await sendRequest<AuthLoginManagerUserResponse>(request)
	}

	async recoverPassword(
		data: RecoverPasswordManagerUserCommandRequest
	): AsyncApiResponse<any> {
		const request = {
			post: {
				path: `${process.env.REACT_APP_URL_BASE}/users/managers/auth/forgot-password`,
				body: data,
			},
		}
		return await sendRequest<any>(request)
	}

	async resetPassword(
		data: ResetPasswordManagerUserCommandRequest
	): AsyncApiResponse<any> {
		const request = {
			post: {
				path: `${process.env.REACT_APP_URL_BASE}/users/managers/auth/reset-password`,
				body: data,
			},
		}
		return await sendRequest<any>(request)
	}

	async getRecords(
		managerUserId: string
	): AsyncApiResponse<ReadManagerUserRecordsQueryResponse[]> {
		const request = {
			get: {
				path: `${process.env.REACT_APP_URL_BASE}/users/manager/audit/${managerUserId}`,
			},
		}
		return await sendRequest<ReadManagerUserRecordsQueryResponse[]>(request)
	}
}
