import {
	AuthLoginManagerUserRequest,
	CreateManagerUserCommandRequest,
	RecoverPasswordManagerUserCommandRequest,
	ResetPasswordManagerUserCommandRequest,
	UpdateManagerUserCommandRequest,
} from '../../domain/entities/managerUser'
import { QueryModel } from '../../domain/models/queryModel'
import {
	ReadManagerUserQuery,
	ReadManagerUsersQuery,
	ReadManagerUsersRecordsQuery,
} from '../../application/queries/managerUser'
import {
	CreateManagerUserCommand,
	ResetPasswordManagerUserCommand,
	UpdateManagerUserCommand,
	LoginManagerUserCommand,
	RecoverPasswordManagerUserCommand,
	RefreshTokenUserCommand,
} from '../../application/commands/managerUser'
import ManagerUserRepository from '../repositories/managerUserRepository'

const repository = new ManagerUserRepository()

export class ManagerUserController {
	static async login(data: AuthLoginManagerUserRequest) {
		return await LoginManagerUserCommand(repository, data)
	}

	static async getById(id: string) {
		return await ReadManagerUserQuery(repository, id)
	}

	static async getAll(query?: QueryModel) {
		return await ReadManagerUsersQuery(repository, query)
	}

	static async create(userData: CreateManagerUserCommandRequest) {
		return await CreateManagerUserCommand(repository, userData)
	}

	static async update(userData: UpdateManagerUserCommandRequest) {
		return await UpdateManagerUserCommand(repository, userData)
	}

	static async refreshToken(refreshToken: string) {
		return await RefreshTokenUserCommand(repository, refreshToken)
	}

	static async recoverPassword(data: RecoverPasswordManagerUserCommandRequest) {
		return await RecoverPasswordManagerUserCommand(repository, data)
	}

	static async resetPassword(data: ResetPasswordManagerUserCommandRequest) {
		return await ResetPasswordManagerUserCommand(repository, data)
	}

	static async getRecords(managerUserId: string) {
		return await ReadManagerUsersRecordsQuery(repository, managerUserId)
	}
}
