import React from 'react'
import { lazy } from 'react'
import { Routes, Route } from 'react-router-dom'

import Layout from '../../components/layout'

import { WinesRoutes } from './winesRoutes/winesRoutes'
import { SubscriptionsRoutes } from './subscriptionsRoutes/subscriptionsRoutes'
import { MembersRoutes } from './membersRoutes/membersRoutes'
import { MemberReactivationRoutes } from './memberUserReactivationRoutes/memberReactivationRoutes'
import { MemberUserSubscriptionsRoutes } from './memberUserSubscriptionRoutes/memberUserSubscriptionsRoutes'
import { SubscriptionRequestChangesRoutes } from './subscriptionRequestChangeRoutes/subscriptionRequestChangesRoutes'
import { CanceledRoutes } from './canceledRoutes/canceledRoutes'
import { UsersRoutes } from './usersRoutes/usersRoutes'
import { CouponRoutes } from './couponRoutes/couponRoutes'
import { PaymentOrderRoutes } from './paymentOrderRoutes/paymentOrderRoutes'
import { HomeRoutes } from './homeRoutes/homeRoutes'
import { ControlRoutes } from './control'
import { RecommendationTypeRoutes } from './recommendationTypeRoutes/recommendationTypeRoutes'
import { GlobalAuditRoutes } from './globalAuditRoutes/globalAuditRoutes'

const NotFound = lazy(() => import('../../pages/404'))

export const privateRoutes = [
	...WinesRoutes,
	...SubscriptionsRoutes,
	...MembersRoutes,
	...MemberUserSubscriptionsRoutes,
	...SubscriptionRequestChangesRoutes,
	...MemberReactivationRoutes,
	...CanceledRoutes,
	...UsersRoutes,
	...CouponRoutes,
	...PaymentOrderRoutes,
	...ControlRoutes,
	...HomeRoutes,
	...RecommendationTypeRoutes,
	...GlobalAuditRoutes,
]

interface IProps {
	rol: string
}

export default function PrivatePages({ rol }: IProps) {
	return (
		<Layout>
			<Routes>
				<Route path="*" element={<NotFound />} />

				{privateRoutes.map((routes: any) => {
					if (routes.rols.find((rols: any) => rols === rol || rols === '*')) {
						return (
							<Route
								key={routes.path}
								path={routes.path}
								element={<routes.element />}
							/>
						)
					}
					return null
				})}
			</Routes>
		</Layout>
	)
}
