import { createSlice } from '@reduxjs/toolkit'

export const campaignSlice = createSlice({
	name: 'campaign',
	initialState: {
		entities: [],
		pagination: {
			page: 0,
			pageSize: 0,
			total: 0,
		},
	},
	reducers: {
		setCampaigns: (state, action) => {
			state.entities = action.payload.entities
			state.pagination = action.payload.pagination
		},
	},
})

export const getCampaigns = (state: any) => state.campaign

export const { setCampaigns } = campaignSlice.actions

export default campaignSlice.reducer
