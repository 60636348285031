import { createSlice } from '@reduxjs/toolkit'

export const wineSlice = createSlice({
	name: 'wine',
	initialState: {
		entities: [],
		pagination: {
			page: 0,
			pageSize: 0,
			total: 0
		},
	},
	reducers: {
		setWine: (state, action) => {
			state.entities = action.payload.entities
			state.pagination = action.payload.pagination
		},
	},
})

export const getWines = (state: any) => state.wine

export const { setWine } = wineSlice.actions

export default wineSlice.reducer
