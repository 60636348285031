import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { redirect } from 'react-router'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import jwt_decode from 'jwt-decode'

import {
	getManagerUser,
	setManagerUser,
} from './presentation/redux/slices/managerUserSlice'
import Routes from './presentation/routes'
import { urlRoutes } from './presentation/routes/config/urlRoutes/urlRoutes'
import { theme } from './presentation/themes'
import { ManagerUserController } from './infraestructure/controllers/managerUserController'
import { formatLocalDate } from './presentation/utils/format'

function App() {
	const dispatch = useDispatch()

	const { logged, accessExpiresIn, refreshExpiresIn, refreshToken } =
		useSelector(getManagerUser)

	const handleLogout = () => {
		localStorage.clear()
		dispatch(setManagerUser({}))
		return redirect(urlRoutes.auth.login.urlTo)
	}

	const handleRequestNewToken = () => {
		ManagerUserController.refreshToken(refreshToken)
			.then((res: any) => {
				const responseManagerUser = {
					logged: true,
					managerUser: jwt_decode(res.data.accessToken),
					...res.data,
				}
				localStorage.setItem('managerUser', JSON.stringify(responseManagerUser))
				dispatch(setManagerUser(responseManagerUser))
			})
			.catch(() => handleLogout())
	}

	useEffect(() => {
		let accessTimer: NodeJS.Timer
		let refreshTimer: NodeJS.Timer
		if (logged) {
			const accessTokenInterval =
				formatLocalDate(accessExpiresIn).getTime() -
				new Date().getTime() -
				12000

			const refreshTokenInterval =
				formatLocalDate(refreshExpiresIn).getTime() - new Date().getTime()

			accessTimer = setInterval(handleRequestNewToken, accessTokenInterval)
			refreshTimer = setTimeout(handleLogout, refreshTokenInterval)
		}
		return () => {
			clearInterval(accessTimer)
			clearTimeout(refreshTimer)
		}
	}, [logged])

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<Routes />
		</ThemeProvider>
	)
}

export default App
