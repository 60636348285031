import { createSlice } from '@reduxjs/toolkit'

export const identificationTypeSlice = createSlice({
    name: 'identificationType',
    initialState: {
        entities: [],
        pagination: {
            page: 0,
            pageSize: 0,
            total: 0
        },
    },
    reducers: {
        setIdentificationType: (state, action) => {
            state.entities = action.payload.entities
            state.pagination = action.payload.pagination
        },
    },
})

export const getIdentificationTypes = (state: any) => state.identificationType

export const { setIdentificationType } = identificationTypeSlice.actions

export default identificationTypeSlice.reducer
