import { lazy } from 'react';

import { urlRoutes } from '../../config/urlRoutes/urlRoutes';

export const UsersRoutes = [
    { 
        nameUrl: "Usuarios",
        path: urlRoutes.users.url, 
        element: lazy(() => import('../../../pages/managerUser')),
        rols: [
            "*",
        ]
    },
    { 
        nameUrl: "Usuarios / Crear",
        path: urlRoutes.users.create.urlTo, 
        element: lazy(() => import('../../../pages/managerUser/create')),
        rols: [
            "*",
        ]
    },
    { 
        nameUrl: "Usuarios / Editar",
        path: urlRoutes.users.edit.urlTo, 
        element: lazy(() => import('../../../pages/managerUser/edit')),
        rols: [
            "*",
        ]
    },

]