import { CityRoutes } from './cityRoutes'
import { RegionRoutes } from './regionRoutes'
import { EntityLogRoutes } from './entityLogRoutes'
import { MembersUsersToPaymentRecurrentRoutes } from './membersUsersToPaymentRecurrentRoutes'
import { CountryRoutes } from './countryRoutes'

export const ControlRoutes = [
	...CityRoutes,
	...RegionRoutes,
	...EntityLogRoutes,
	...MembersUsersToPaymentRecurrentRoutes,
	...CountryRoutes,
]
