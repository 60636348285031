import { createSlice } from '@reduxjs/toolkit'

export const citySlice = createSlice({
    name: 'city',
    initialState: {
        entities: [],
        pagination: {
            page: 0,
            pageSize: 0,
            total: 0
        },
    },
    reducers: {
        setCity: (state, action) => {
            state.entities = action.payload.entities
            state.pagination = action.payload.pagination
        },
    },
})

export const getCities = (state: any) => state.city

export const { setCity } = citySlice.actions

export default citySlice.reducer
