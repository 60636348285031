import * as React from 'react'
import { Box, CircularProgress, Typography } from '@mui/material'

interface ISpinnerProps {
	message?: string
}

export default function Spinner({ message }: ISpinnerProps) {
	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				flexDirection: 'column',
				alignItems: 'center',
				gap: '15px',
				width: '100%',
				height: '100vh',
			}}
		>
			<CircularProgress color="primary" size={70} />
			{message && <Typography fontWeight={700}>{message}</Typography>}
		</Box>
	)
}
