import { lazy } from 'react'

import { urlRoutes } from '../../config/urlRoutes/urlRoutes'

export const MembersRoutes = [
	{
		nameUrl: 'Socios',
		path: urlRoutes.memberUser.url,
		element: lazy(() => import('../../../pages/memberUser')),
		rols: ['*'],
	},
	{
		nameUrl: 'Socios / Crear',
		path: urlRoutes.memberUser.create.urlTo,
		element: lazy(() => import('../../../pages/memberUser/create')),
		rols: ['*'],
	},
	{
		nameUrl: 'Socios / Editar',
		path: `${urlRoutes.memberUser.url}/:id`,
		element: lazy(() => import('../../../pages/memberUser/edit')),
		rols: ['*'],
	},
]
