import { lazy } from 'react'

import { urlRoutes } from '../../../config/urlRoutes/urlRoutes'

export const EntityLogRoutes = [
	{
		nameUrl: 'Logs',
		path: urlRoutes.control.entityLog.url,
		element: lazy(() => import('../../../../pages/control/entityLog')),
		rols: ['*'],
	},
]
