import React from 'react'
import {
	MilitaryTechOutlined as MilitaryTechOutlinedIcon,
	AddReactionOutlined as AddReactionOutlinedIcon,
	GroupOutlined as GroupOutlinedIcon,
	ManageAccountsOutlined as ManageAccountsOutlinedIcon,
	DiscountOutlined as DiscountOutlinedIcon,
	SettingsOutlined as SettingsOutlinedIcon,
	ShoppingCartCheckout as ShoppingCartCheckoutIcon,
	LocalMallOutlined as LocalMallOutlinedIcon,
	PublishedWithChangesOutlined as PublishedWithChangesOutlinedIcon,
	WineBarOutlined as WineBarOutlinedIcon,
	CancelOutlined as CancelOutlinedIcon,
	Home as HomeIcon,
	StarBorder as StarBorderIcon,
	FollowTheSigns as FollowTheSignsIcon,
} from '@mui/icons-material'

import { urlRoutes } from '../../routes/config/urlRoutes/urlRoutes'

export const drawerList = [
	{
		module: null,
		items: [
			{
				icon: <HomeIcon />,
				text: 'Panel Inicio',
				link: urlRoutes.home.url,
				children: [],
			},
		],
	},
	{
		module: null,
		items: [
			{
				icon: <GroupOutlinedIcon />,
				text: 'Socios',
				link: urlRoutes.memberUser.url,
				children: [],
			},
		],
	},
	{
		module: null,
		items: [
			{
				icon: <LocalMallOutlinedIcon />,
				text: 'Pedidos',
				link: urlRoutes.memberUserSubscription.url,
				children: [],
			},
		],
	},
	{
		module: null,
		items: [
			{
				icon: <ShoppingCartCheckoutIcon />,
				text: 'Pagos',
				link: urlRoutes.paymentOrder.url,
				children: [],
			},
		],
	},
	{
		module: null,
		items: [
			{
				icon: <DiscountOutlinedIcon />,
				text: 'Cupones',
				link: urlRoutes.coupon.url,
				children: [],
			},
		],
	},
	{
		module: null,
		items: [
			{
				icon: <MilitaryTechOutlinedIcon />,
				text: 'Membresías',
				link: urlRoutes.subscriptions.url,
				children: [],
			},
		],
	},
	{
		module: null,
		items: [
			{
				icon: <PublishedWithChangesOutlinedIcon />,
				text: 'Cambio de Membresía',
				link: urlRoutes.subscriptionRequestChange.url,
				children: [],
			},
		],
	},
	{
		module: null,
		items: [
			{
				icon: <AddReactionOutlinedIcon />,
				text: 'Reactivación de socios',
				link: urlRoutes.memberUserReactivation.url,
				children: [],
			},
		],
	},
	{
		module: null,
		items: [
			{
				icon: <StarBorderIcon />,
				text: 'Tipos de recomendaciones',
				link: urlRoutes.recommendationsTypes.url,
				children: [],
			},
		],
	},
	{
		module: null,
		items: [
			{
				icon: <FollowTheSignsIcon />,
				text: 'Auditoría de socios',
				link: urlRoutes.globalAudit.url,
				children: [],
			},
		],
	},
	{
		module: null,
		items: [
			{
				icon: <CancelOutlinedIcon />,
				text: 'Cancelaciones',
				link: urlRoutes.canceledSubscription.url,
				children: [],
			},
		],
	},
	{
		module: null,
		items: [
			{
				icon: <ManageAccountsOutlinedIcon />,
				text: 'Managers',
				link: urlRoutes.users.url,
				children: [],
			},
		],
	},
	{
		module: null,
		items: [
			{
				icon: <WineBarOutlinedIcon />,
				text: 'Vinos',
				link: urlRoutes.wines.url,
				children: [],
			},
		],
	},
	{
		module: null,
		items: [
			{
				icon: <SettingsOutlinedIcon />,
				text: 'Control',
				link: null,
				children: [
					{ text: 'Provincias', link: urlRoutes.control.region.url },
					{ text: 'Ciudades', link: urlRoutes.control.city.url },
					{ text: 'Países', link: urlRoutes.control.countries.url },
					{ text: 'Logs', link: urlRoutes.control.entityLog.url },
					{
						text: 'Cobro recurrente',
						link: urlRoutes.control.memberUserToRecurrentPayment.url,
					},
				],
			},
		],
	},
]
