import { createSlice } from '@reduxjs/toolkit'

export const userTypeSlice = createSlice({
	name: 'userType',
	initialState: {
		entities: [],
		pagination: {
			page: 0,
			pageSize: 0,
			total: 0,
		},
	},
	reducers: {
		setUserTypes: (state, action) => {
			state.entities = action.payload.entities
			state.pagination = action.payload.pagination
		},
	},
})

export const getUserTypes = (state: any) => state.userType

export const { setUserTypes } = userTypeSlice.actions

export default userTypeSlice.reducer
