import { lazy } from 'react';

import { urlRoutes } from '../../config/urlRoutes/urlRoutes';

export const SubscriptionsRoutes = [
    { 
        nameUrl: "Membresías",
        path: urlRoutes.subscriptions.url, 
        element: lazy(() => import('../../../pages/subscription')),
        rols: [
            "*",
        ]
    },
    { 
        nameUrl: "Membresías / Crear",
        path: urlRoutes.subscriptions.create.urlTo, 
        element: lazy(() => import('../../../pages/subscription/create')),
        rols: [
            "*",
        ]
    },
    {
        nameUrl: "Membresías / Editar",
        path: `${urlRoutes.subscriptions.url}/:id`,
        element: lazy(() => import('../../../pages/subscription/edit')),
        rols: [
            "*",
        ]
    }

]