import React, { Suspense } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import PublicRoutes from './publicRoutes'
import PrivateRoutes from './privateRoutes'

import PublicPages from './publicPages/publicPages'
import PrivatePages from './privatePages/privatePages'

import { urlRoutes } from './config/urlRoutes/urlRoutes'
import { useSelector } from 'react-redux'
import { getManagerUser } from '../redux/slices/managerUserSlice'
import Spinner from '../components/elements/spinner'

export default function Index() {
	const userManager = useSelector(getManagerUser)

	return (
		<Router>
			<Suspense fallback={<Spinner />}>
				<div>
					<Routes>
						<Route element={<PublicRoutes isLoggedIn={userManager?.logged} />}>
							<Route path={urlRoutes.auth.url} element={<PublicPages />} />
						</Route>

						<Route element={<PrivateRoutes isLoggedIn={userManager?.logged} />}>
							<Route path={'/*'} element={<PrivatePages rol="Admin" />} />
						</Route>
					</Routes>
				</div>
			</Suspense>
		</Router>
	)
}
