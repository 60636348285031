import moment from 'moment'
import { ContentState, convertFromHTML, convertToRaw } from 'draft-js'

//Date-Time
export const formatLocalDate = (data: string | Date) =>
	moment.utc(data).local().toDate()

//From UTC to Local
export const formatLocalDateString = (data: string | Date) =>
	moment(data).utc().local().format('DD/MM/YYYY')

export const formatLocalDateTimeString = (data: string | Date) =>
	moment.utc(data).local().format('DD/MM/YYYY HH:mm:ss')

export const formatUtcDateTimeInFilter = (data: Date) => {
	return moment(data).utc().format('YYYY-MM-DD HH:mm:ss')
}

export const formatUtcDate = (data: string | Date, format?: string) => {
	if (String(data).split('/').length === 3) {
		return moment(data, format || 'DD/MM/YYYY')
			.utc()
			.toDate()
	}
	return moment(data).utc().toDate()
}

//Extras
export const formatToHtml = (data: string) => {
	const contentHTML = convertFromHTML(data)
	const state = ContentState.createFromBlockArray(
		contentHTML.contentBlocks,
		contentHTML.entityMap
	)
	return JSON.stringify(convertToRaw(state))
}

export const renovationArr = () => {
	const arrRenovation: any = []
	for (let i = 1; i <= 24; i++) arrRenovation.push({ value: i, label: i })
	return arrRenovation
}

export const validatePassword = (valor: any) => {
	const regExp =
		/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.]{8,}$/ // NOSONAR
	return regExp.test(valor)
}
export const validateOnlyNumberAndDoit = (valor: any) => {
	const regExp = /^([0-9]+\.?[0-9]{0,2})$/ // NOSONAR
	return regExp.test(valor)
}

export const validateEmail = (email: string) => {
	return String(email)
		.toLowerCase()
		.match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		)
}

export const validateOnlyNumber = (valor: string) => {
	const regExp = /^\d*$/
	return regExp.test(valor)
}

export const validateOnlyLettersAndNumbers = (value: string) => {
	const regExp = /^[\da-zA-Z]*$/
	return regExp.test(value)
}

export const validatePhoneCode = (valor: string) => {
	const regExp = /^[+]\d*$/
	return regExp.test(valor)
}

export const validateOnlyLetters = (value: string) => {
	const regExp = /^[A-Z]*$/
	return regExp.test(value)
}

export const validateOnlyLettersAndSpaces = (value: string) => {
	const regExp = /^[a-zA-ZÀ-ÿ\s´]+$/u
	return regExp.test(value)
}
