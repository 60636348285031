import { lazy } from 'react'

import { urlRoutes } from '../../config/urlRoutes/urlRoutes'

export const PaymentOrderRoutes = [
	{
		nameUrl: 'Pagos con tokens',
		path: urlRoutes.paymentOrder.url,
		element: lazy(() => import('../../../pages/paymentOrder')),
		rols: ['*'],
	},
	{
		nameUrl: 'Pagos con tokens / Crear',
		path: urlRoutes.paymentOrder.create.urlTo,
		element: lazy(() => import('../../../pages/paymentOrder/create')),
		rols: ['*'],
	},
	{
		nameUrl: 'Socios / Detalle',
		path: urlRoutes.paymentOrder.edit.urlTo,
		element: lazy(() => import('../../../pages/paymentOrder/details')),
		rols: ['*'],
	},
]
