import { createSlice } from '@reduxjs/toolkit'

export const recommendationTypeSlice = createSlice({
	name: 'recommendationType',
	initialState: {
		entities: [],
		pagination: {
			page: 0,
			pageSize: 0,
			total: 0,
		},
	},
	reducers: {
		setRecommendationsTypes: (state, action) => {
			state.entities = action.payload.entities
			state.pagination = action.payload.pagination
		},
	},
})

export const getRecommendationsTypes = (state: any) => state.recommendationType

export const { setRecommendationsTypes } = recommendationTypeSlice.actions

export default recommendationTypeSlice.reducer
