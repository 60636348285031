import { lazy } from 'react'

import { urlRoutes } from '../../config/urlRoutes/urlRoutes'

export const MemberUserSubscriptionsRoutes = [
	{
		nameUrl: 'Pedidos',
		path: urlRoutes.memberUserSubscription.url,
		element: lazy(() => import('../../../pages/memberUserSubscription')),
		rols: ['*'],
	},
	{
		nameUrl: 'Pedidos / Crear',
		path: urlRoutes.memberUserSubscription.create.urlTo,
		element: lazy(() => import('../../../pages/memberUserSubscription/create')),
		rols: ['*'],
	},
]
