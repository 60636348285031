import { createSlice } from '@reduxjs/toolkit'

export const subscriptionsSlice = createSlice({
    name: 'subscriptions',
    initialState: {
        entities: [],
        pagination: {
            page: 0,
            pageSize: 0,
            total: 0
        },
    },
    reducers: {
        setSubscriptions: (state, action) => {
            state.entities = action.payload.entities
            state.pagination = action.payload.pagination
        },
    },
})

export const getSubscriptions = (state: any) => state.subscriptions

export const { setSubscriptions } = subscriptionsSlice.actions

export default subscriptionsSlice.reducer
