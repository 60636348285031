import { createSlice } from '@reduxjs/toolkit'

export const couponTypeSlice = createSlice({
	name: 'couponType',
	initialState: {
		entities: [],
		pagination: {
			page: 0,
			pageSize: 0,
			total: 0,
		},
	},
	reducers: {
		setCouponTypes: (state, action) => {
			state.entities = action.payload.entities
			state.pagination = action.payload.pagination
		},
	},
})

export const getCouponTypes = (state: any) => state.couponType

export const { setCouponTypes } = couponTypeSlice.actions

export default couponTypeSlice.reducer
