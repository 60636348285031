import { lazy } from 'react'

import { urlRoutes } from '../../config/urlRoutes/urlRoutes'

export const SubscriptionRequestChangesRoutes = [
	{
		nameUrl: 'cambio_membresia',
		path: urlRoutes.subscriptionRequestChange.url,
		element: lazy(() => import('../../../pages/subscriptionRequestChange')),
		rols: ['*'],
	},
	{
		nameUrl: 'cambio_membresia / Crear',
		path: urlRoutes.subscriptionRequestChange.create.urlTo,
		element: lazy(
			() => import('../../../pages/subscriptionRequestChange/create')
		),
		rols: ['*'],
	},
]
