import { createSlice } from '@reduxjs/toolkit'

const paginationEmpty = {
	page: 0,
	pageSize: 0,
	total: 0,
}
export const memberUserSlice = createSlice({
	name: 'memberUser',
	initialState: {
		entities: [],
		records: [],
		pagination: paginationEmpty,
		paginationRecords: paginationEmpty,
	},
	reducers: {
		setMemberUser: (state, action) => {
			state.entities = action.payload.entities
			state.pagination = action.payload.pagination
		},
		setMemberUserEntities: (state, action) => {
			state.entities = action.payload
		},
		setRecords: (state, action) => {
			state.records = action.payload.entities
			state.paginationRecords = action.payload.pagination
		},
	},
})

export const getMemberUsers = (state: any) => state.memberUser

export const { setMemberUser, setMemberUserEntities, setRecords } =
	memberUserSlice.actions

export default memberUserSlice.reducer
