import { lazy } from 'react';

import { urlRoutes } from '../../config/urlRoutes/urlRoutes';

export const WinesRoutes = [
    { 
        nameUrl: "Vinos",
        path: urlRoutes.wines.url, 
        element: lazy(() => import('../../../pages/wine')),
        rols: [
            "*",
        ]
    },
    { 
        nameUrl: "Vinos / Crear",
        path: urlRoutes.wines.create.urlTo, 
        element: lazy(() => import('../../../pages/wine/create')),
        rols: [
            "*",
        ]
    },
    {
        nameUrl: "Vinos / Editar",
        path: `${urlRoutes.wines.url}/:id`,
        element: lazy(() => import('../../../pages/wine/edit')),
        rols: [
            "*",
        ]
    }

]