import { createSlice } from '@reduxjs/toolkit'

export interface ILoggedUser {
	fullName: string
	id: string
	userType: string
	username: string
}

export interface IUserLocalStorage {
	accessExpiresIn: string
	accessToken: string
	logged: boolean
	managerUser: ILoggedUser
	refreshExpiresIn: string
	refreshToken: string
}

const user: IUserLocalStorage = JSON.parse(
	localStorage.getItem('managerUser') || '{}'
)

export const managerUserSlice = createSlice({
	name: 'managerUser',
	initialState: {
		logged: user?.logged,
		managerUser: user?.managerUser,
		accessToken: user?.accessToken,
		refreshToken: user?.refreshToken,
		accessExpiresIn: user?.accessExpiresIn,
		refreshExpiresIn: user?.refreshExpiresIn
	},
	reducers: {
		setManagerUser: (state, action) => {
			state.logged = action.payload.logged
			state.managerUser = action.payload.managerUser
			state.accessToken = action.payload.accessToken
			state.refreshToken = action.payload.refreshToken
			state.accessExpiresIn = action.payload.accessExpiresIn
			state.refreshExpiresIn = action.payload.refreshExpiresIn
		}
	}
})

export const getManagerUser = (state: any) => state.managerUser

export const { setManagerUser } = managerUserSlice.actions

export default managerUserSlice.reducer
