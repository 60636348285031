import { lazy } from 'react'

export const HomeRoutes = [
	{
		nameUrl: 'Home',
		path: "/",
		element: lazy(() => import('../../../pages/home')),
		rols: ['*'],
	},
]
