import { createSlice } from '@reduxjs/toolkit'

export const statusTypeSlice = createSlice({
	name: 'statusType',
	initialState: {
		entities: [],
		pagination: {
			page: 0,
			pageSize: 0,
			total: 0
		},
	},
	reducers: {
		setStatusType: (state, action) => {
			state.entities = action.payload.entities
			state.pagination = action.payload.pagination
		},
	},
})

export const getStatusTypes = (state: any) => state.statusType

export const { setStatusType } = statusTypeSlice.actions

export default statusTypeSlice.reducer
