import { configureStore } from '@reduxjs/toolkit'

import managersReducer from './slices/managersSlice'
import managerUserReducer from './slices/managerUserSlice'
import wineReducer from './slices/wineSlice'
import subscriptionsReducer from './slices/subscriptionsSlice'
import roleReducer from './slices/roleSlice'
import identificationTypeReducer from './slices/identificationTypeSlice'
import memberUserReducer from './slices/memberUserSlice'
import memberUserSubscriptionReducer from './slices/memberUserSubscriptionSlice'
import regionReducer from './slices/regionSlice'
import cityReducer from './slices/citySlice'
import couponReducer from './slices/couponSlice'
import statusTypeReducer from './slices/statusTypeSlice'
import couponTypeReducer from './slices/couponTypeSlice'
import paymentOrderReducer from './slices/paymentOrderSlice'
import userTypeReducer from './slices/userTypeSlice'
import campaignReducer from './slices/campaignSlice'
import recommendationTypeReducer from './slices/recommendationTypeSlice'
import countryReducer from './slices/countrySlice'

export default configureStore({
	reducer: {
		managers: managersReducer,
		managerUser: managerUserReducer,
		wine: wineReducer,
		subscriptions: subscriptionsReducer,
		role: roleReducer,
		identificationType: identificationTypeReducer,
		memberUser: memberUserReducer,
		memberUserSubscription: memberUserSubscriptionReducer,
		region: regionReducer,
		city: cityReducer,
		coupon: couponReducer,
		statusType: statusTypeReducer,
		couponType: couponTypeReducer,
		paymentOrder: paymentOrderReducer,
		userType: userTypeReducer,
		campaign: campaignReducer,
		recommendationType: recommendationTypeReducer,
		country: countryReducer,
	},
})
