import { lazy } from 'react'

import { urlRoutes } from '../../config/urlRoutes/urlRoutes'

export const GlobalAuditRoutes = [
	{
		nameUrl: 'Auditoría de socios',
		path: urlRoutes.globalAudit.url,
		element: lazy(() => import('../../../pages/globalAudit')),
		rols: ['*'],
	},
]
