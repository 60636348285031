import { createSlice } from '@reduxjs/toolkit'

export const couponSlice = createSlice({
	name: 'coupon',
	initialState: {
		entities: [],
		pagination: {
			page: 0,
			pageSize: 0,
			total: 0,
		},
	},
	reducers: {
		setCoupons: (state, action) => {
			state.entities = action.payload.entities
			state.pagination = action.payload.pagination
		},
	},
})

export const getCoupons = (state: any) => state.coupon

export const { setCoupons } = couponSlice.actions

export default couponSlice.reducer
