export const urlRoutes = {
	home: {
		url: '/',
	},
	auth: {
		url: '/autenticacion/*',
		login: {
			url: '/login',
			urlTo: '/autenticacion/login',
		},
		recover: {
			url: '/recuperar',
			urlTo: '/autenticacion/recuperar',
		},
		recoverPassword: {
			url: '/cambiar-contrasena',
			urlTo: '/autenticacion/cambiar-contrasena',
		},
	},
	wines: {
		url: '/vinos',

		create: {
			urlTo: '/vinos/crear',
		},
	},
	subscriptions: {
		url: '/membresias',

		create: {
			urlTo: '/membresias/crear',
		},
	},
	memberUser: {
		url: '/socios',

		create: {
			urlTo: '/socios/crear',
		},
		edit: {
			urlTo: '/socios/:id',
		},
	},
	memberUserReactivation: {
		url: '/reactivar-socios',

		create: {
			urlTo: '/reactivar-socios/crear',
		},
		visualize: {
			urlTo: '/reactivar-socios/:id',
		},
	},
	memberUserSubscription: {
		url: '/pedidos',
		create: {
			urlTo: '/pedidos/crear',
		},
	},
	subscriptionRequestChange: {
		url: '/cambio_membresia',
		create: {
			urlTo: '/cambio_membresia/crear',
		},
	},
	recommendationsTypes: {
		url: '/tipos-recomendaciones',

		create: {
			urlTo: '/tipos-recomendaciones/crear',
		},
		edit: {
			urlTo: '/tipos-recomendaciones/:id',
		},
	},
	canceledSubscription: {
		url: '/cancelaciones',
	},
	globalAudit: {
		url: '/auditoria-socios',
	},
	users: {
		url: '/usuarios',

		create: {
			urlTo: '/usuarios/crear',
		},
		edit: {
			urlTo: '/usuarios/:id',
		},
	},
	coupon: {
		url: '/cupones',

		create: {
			urlTo: '/cupones/crear',
		},
	},
	paymentOrder: {
		url: '/pagos-con-tokens',

		create: {
			urlTo: '/pagos-con-tokens/crear',
		},
		edit: {
			urlTo: '/pagos-con-tokens/:id',
		},
	},
	control: {
		region: {
			url: '/control/regiones',

			create: {
				urlTo: '/control/regiones/crear',
			},
		},
		city: {
			url: '/control/ciudades',

			create: {
				urlTo: '/control/ciudades/crear',
			},
		},
		countries: {
			url: '/control/paises',

			create: {
				urlTo: '/control/paises/crear',
			},
			edit: {
				urlTo: '/control/paises/:id',
			},
		},
		entityLog: {
			url: '/control/registros-logs',
		},
		memberUserToRecurrentPayment: {
			url: '/control/socios-por-cobrar',
		},
	},
}
