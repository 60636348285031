import { lazy } from 'react'

import { urlRoutes } from '../../config/urlRoutes/urlRoutes'

export const MemberReactivationRoutes = [
	{
		nameUrl: 'Campañas',
		path: urlRoutes.memberUserReactivation.url,
		element: lazy(() => import('../../../pages/campains')),
		rols: ['*'],
	},
	{
		nameUrl: 'Campañas / Crear',
		path: urlRoutes.memberUserReactivation.create.urlTo,
		element: lazy(() => import('../../../pages/campains/create')),
		rols: ['*'],
	},
	{
		nameUrl: 'Campañas / Visualizar',
		path: `${urlRoutes.memberUserReactivation.url}/:id`,
		element: lazy(
			() => import('../../../pages/campains/visualize')
		),
		rols: ['*'],
	},
]
