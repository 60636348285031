import { lazy } from 'react'

import { urlRoutes } from '../../config/urlRoutes/urlRoutes'

export const RecommendationTypeRoutes = [
	{
		nameUrl: 'Tipos de Recomendaciones',
		path: urlRoutes.recommendationsTypes.url,
		element: lazy(() => import('../../../pages/recommendationsTypes')),
		rols: ['*'],
	},
	{
		nameUrl: 'Tipos de Recomendaciones / Crear',
		path: urlRoutes.recommendationsTypes.create.urlTo,
		element: lazy(() => import('../../../pages/recommendationsTypes/ create')),
		rols: ['*'],
	},
	{
		nameUrl: 'Tipos de Recomendaciones / Editar',
		path: `${urlRoutes.recommendationsTypes.url}/:id`,
		element: lazy(() => import('../../../pages/recommendationsTypes/ edit')),
		rols: ['*'],
	},
]
