import { lazy } from 'react';

import { urlRoutes } from '../../../config/urlRoutes/urlRoutes';

export const CityRoutes = [
    { 
        nameUrl: "Ciudades",
        path: urlRoutes.control.city.url, 
        element: lazy(() => import('../../../../pages/control/city')),
        rols: [
            "*",
        ]
    },
    { 
        nameUrl: "Ciudades / Crear",
        path: urlRoutes.control.city.create.urlTo, 
        element: lazy(() => import('../../../../pages/control/city/create')),
        rols: [
            "*",
        ]
    },
    { 
        nameUrl: "Ciudades / Editar",
        path: `${urlRoutes.control.city.url}/:id`, 
        element: lazy(() => import('../../../../pages/control/city/edit')),
        rols: [
            "*",
        ]
    },
]