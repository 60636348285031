import React, { ReactNode, useState } from 'react'
import { useSelector } from 'react-redux'
import {
	AppBar,
	Box,
	CssBaseline,
	Drawer,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	IconButton,
	Toolbar,
	Collapse,
	Divider,
} from '@mui/material'
import {
	Menu as MenuIcon,
	ExpandLess,
	ExpandMore,
	CircleOutlined as CircleOutlinedIcon,
} from '@mui/icons-material'
import { useNavigate, NavLink } from 'react-router-dom'
import './style.css'

import Logo from '../../assets/logoSorvo.svg'
import { getManagerUser } from '../../redux/slices/managerUserSlice'
import AccountMenu from './accountMenu'
import { drawerList } from './drawer'

const drawerWidth = 240

interface Props {
	window?: () => Window
	children: ReactNode
}

export default function LayoutManagement(props: Props) {
	const userManager = useSelector(getManagerUser)
	const { window: windowMui, children } = props
	const [mobileOpen, setMobileOpen] = useState<boolean>(false)
	const [mobileOpenNav, setMobileOpenNav] = useState<boolean>(false)
	const [open, setOpen] = useState<number>(-1)
	const navigate = useNavigate()

	const handleClick = (index: any) => () => {
		if (index === open) {
			setOpen(-1)
		} else {
			setOpen(index)
		}
	}

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen)
		setMobileOpenNav(false)
	}

	const handleDrawerToggleNav = () => {
		setMobileOpenNav(!mobileOpenNav)
		setMobileOpen(false)
	}

	const drawer = (
		<div>
			<List sx={{ paddingTop: !mobileOpen ? '10px' : '20px' }}>
				{drawerList.map((data: any, index: number) => (
					<div key={index}>
						{data.module && <Divider />}
						{data.items.map((dataItems: any, index: number) =>
							!dataItems.children[0] ? (
								<NavLink
									key={index}
									to={dataItems.link}
									className={({ isActive }) =>
										isActive ? 'activeStyle' : 'notActiveStyle'
									}
								>
									<ListItemButton>
										<ListItemIcon>{dataItems.icon}</ListItemIcon>
										<ListItemText
											style={{ padding: '5px 0' }}
											primary={dataItems.text}
										/>
									</ListItemButton>
								</NavLink>
							) : (
								<div key={index}>
									<ListItemButton
										style={{
											color: open === dataItems.itemId ? '#fff' : '#fff',
										}}
										onClick={handleClick(dataItems.itemId)}
									>
										<ListItemIcon
											style={{
												color: open === dataItems.itemId ? '#fff' : '#fff',
											}}
										>
											{dataItems.icon}
										</ListItemIcon>
										<ListItemText primary={dataItems.text} />
										{open === dataItems.itemId ? (
											<ExpandLess />
										) : (
											<ExpandMore />
										)}
									</ListItemButton>

									<Collapse
										in={open === dataItems.itemId}
										timeout="auto"
										unmountOnExit
									>
										<List
											style={{ background: '#202020' }}
											component="div"
											disablePadding
										>
											{dataItems.children.map(
												(dataChildren: any, index: number) => (
													<NavLink
														key={index}
														to={dataChildren.link}
														className={({ isActive }) =>
															isActive ? 'activeStyle' : 'notActiveStyle'
														}
													>
														<ListItemButton sx={{ pl: 4 }}>
															<ListItemIcon sx={{ minWidth: '20px' }}>
																<CircleOutlinedIcon
																	style={{ fontSize: '12px' }}
																/>
															</ListItemIcon>
															<ListItemText primary={dataChildren.text} />
														</ListItemButton>
													</NavLink>
												)
											)}
										</List>
									</Collapse>
								</div>
							)
						)}
					</div>
				))}
			</List>
		</div>
	)

	const container =
		windowMui !== undefined ? () => windowMui().document.body : undefined

	return (
		<Box sx={{ display: { xs: 'block', sm: 'flex' } }}>
			<CssBaseline />
			<AppBar
				position="fixed"
				sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
			>
				<Toolbar>
					{userManager?.logged && (
						<IconButton
							color="inherit"
							aria-label="open drawer"
							edge="start"
							onClick={handleDrawerToggle}
							sx={{ mr: 2, display: { sm: 'none' } }}
						>
							<MenuIcon />
						</IconButton>
					)}

					<Box sx={{ flexGrow: 1 }}>
						<a style={{ cursor: 'pointer' }} onClick={() => navigate('/')}>
							<img alt="Logo" width={130} src={Logo} />
						</a>
					</Box>
					{userManager?.logged && (
						<div style={{ display: 'inline-block' }}>
							<AccountMenu />
						</div>
					)}
				</Toolbar>
			</AppBar>
			<Box
				component="nav"
				sx={{
					width: { sm: userManager?.logged ? drawerWidth : 0 },
					flexShrink: { sm: 0 },
				}}
			>
				<Drawer
					container={container}
					variant="temporary"
					open={mobileOpenNav}
					onClose={handleDrawerToggleNav}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
					sx={{
						display: { xs: 'block', sm: 'none' },
						'& .MuiDrawer-paper': {
							boxSizing: 'border-box',
							width: drawerWidth,
						},
					}}
				>
					{/* {drawerNav} */}
				</Drawer>
			</Box>
			{userManager?.logged && (
				<>
					<Drawer
						container={container}
						variant="temporary"
						open={mobileOpen}
						onClose={handleDrawerToggle}
						ModalProps={{
							keepMounted: true, // Better open performance on mobile.
						}}
						sx={{
							display: { xs: 'block', sm: 'none' },
							'& .MuiDrawer-paper': {
								boxSizing: 'border-box',
								width: drawerWidth,
								background: '#2A2A2A',
							},
						}}
					>
						<Toolbar />
						<Box sx={{ overflow: 'auto' }}>{drawer}</Box>
					</Drawer>
					<Drawer
						variant="permanent"
						sx={{
							display: { xs: 'none', sm: 'block' },
							'& .MuiDrawer-paper': {
								boxSizing: 'border-box',
								width: drawerWidth,
							},
						}}
						open
					>
						<Toolbar />
						<Box sx={{ overflow: 'auto' }}>{drawer}</Box>
					</Drawer>
				</>
			)}
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					p: userManager?.logged ? { xs: '24px 0', sm: 3 } : 0,
					pt: userManager?.logged ? 5 : 0,
					width: { sm: `calc(100% - ${drawerWidth}px)` },
				}}
			>
				<Toolbar
					sx={{
						'&.MuiToolbar-root': {
							minHeight: userManager?.logged ? '64px' : 0,
						},
					}}
				/>
				<div style={{ minHeight: '75vh' }}>{children}</div>
			</Box>
		</Box>
	)
}
