import { createSlice } from '@reduxjs/toolkit'

export const roleSlice = createSlice({
	name: 'role',
	initialState: {
		entities: [],
		pagination: {
			page: 0,
			pageSize: 0,
			total: 0
		},
	},
	reducers: {
		setRole: (state, action) => {
			state.entities = action.payload.entities
			state.pagination = action.payload.pagination
		},
	},
})

export const getRoles = (state: any) => state.role

export const { setRole } = roleSlice.actions

export default roleSlice.reducer
